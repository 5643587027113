import React, { useEffect, useState, useRef } from 'react';
import {useSlate, useSelected,useFocused} from 'slate-react'
import {Range, Transforms} from 'slate'
import Button from '../common/Button'
import Icon from '../common/Icon'
import { toggleBlock, toggleMark, isMarkActive, addMarkData, isBlockActive,activeMark} from '../utils/SlateUtilityFunctions.js'
import useFormat from '../utils/customHooks/useFormat.js'
import defaultToolbarGroups from './toolbarGroups.js'
import styles from './Toolbar.module.css'

const uploadFile = (file, onSuccess) => {
    const token = sessionStorage.getItem('token')
    let proceed = true
    let fileType = file.type
    console.log(fileType)
    if (file.name.endsWith(".zip")){
        fileType = "application/zip"
    }
    if (file.name.endsWith(".rar")){
        fileType = "application/vnd.rar"
    }
    fetch(
        'https://tkpoluslab.ru:8081/api/v1/files/upload',
        {
            method: 'POST',
            body: file,
            headers: {
                'content-type': fileType,
                'content-length': `${file.size}`,
                'authorization': 'Bearer '+token,
                },
        })
        .catch((err) => {proceed = false})
        .then((resp)=>
        {
            if (proceed && resp.ok){
                resp.json().then((json)=>
                {
                    onSuccess(json.FileName)
                })
            }else{
                alert("Ошибка загрузки")
            }
        }) 
}

const Toolbar = (props)=>{
    const editor = useSlate();
    const [toolbarGroups,setToolbarGroups] = useState(defaultToolbarGroups);
    const imageRef = useRef(null);
    const videoRef = useRef(null);
    const randomRef = useRef(null);

    const BlockButton = ({format}) =>{
        return (
            <Button active={isBlockActive(editor,format)} format={format} onMouseDown={
                e=>{
                    e.preventDefault();
                    toggleBlock(editor,format)
                }
            }>
                <Icon icon={format}/>
            </Button>
        )
    }
    const MarkButton = ({format})=>{
        return(
            <Button active={isMarkActive(editor,format)} format={format} onMouseDown={
                e=>{
                    e.preventDefault();
                    toggleMark(editor,format)
                }
            }>
                <Icon icon={format}/>
            </Button>
        )
    }
    const Dropdown = ({format,options}) => {
        return (
            <select value={activeMark(editor,format)} onChange = {e => changeMarkData(e,format)}>
                {
                    options.map((item,index) => 
                        <option key={index} value={item.value}>{item.text}</option>
                    )
                }
            </select>
        )
    }
    const Separator = () => {
        return (
            <div>
                <Button active={false} onMouseDown={
                    (e)=>{
                            Transforms.insertNodes(editor,{type: 'separator', alignSelf: 'left', children: [{text:""}]})
                            Transforms.insertNodes(editor,{type: 'paragraph', children: [{text:""}]})
                        }
                    }>
                    <Icon icon={'separator'}/>
                </Button>
            </div>
        )
    }

    const Image = () => {
        return(
            <div>
                <input type="file"
                    id="imageInput" name="imageInput"
                    accept="image/*"
                    onInput = {(e)=>{
                        if (e.target.files) {
                            uploadFile(e.target.files[0],(fileName) => {
                                Transforms.insertNodes(editor,{type: 'image', url: 'https://tkpoluslab.ru:8081/uploads/'+fileName, scale:100, background: true, alignImage:'right', children: [{text:""}]})
                            })
                        }
                    }}
                    onClick={(e)=>{
                        e.value = ''
                        e.target.value = ''
                    }}
                    style={{display:'none'}}
                    ref = {imageRef}
                />
                <Button active={false} onMouseDown={
                    (e)=>{
                            imageRef.current.click()
                            //Transforms.insertNodes(editor,{type: 'image', url: url, children: [{text:""}]})
                        }
                    }>
                    <Icon icon={'image'}/>
                </Button>
            </div>
            
        )
    }

    const ImageGallery = () => {
        return (
            <div>
                <Button active={false} onMouseDown={
                    (e)=>{
                            Transforms.insertNodes(editor,{type: 'gallery', displayType:'slider', items: [], children: [{text:""}]})
                            Transforms.insertNodes(editor,{type: 'paragraph', children: [{text:""}]})
                        }
                    }>
                    <Icon icon={'gallery'}/>
                </Button>
            </div>
        )
    }

    const YoutubeButton = () => {
        return (
            <div>
                <Button active={false} onMouseDown={
                    (e)=>{
                            const videoUrl = prompt("Введите ссылку на Youtube:")
                            if (videoUrl){
                                const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
                                const match = videoUrl.match(regExp)
                                if (match&&match[7].length == 11){
                                    Transforms.insertNodes(editor,{type: 'youtube', url:match[7], children: [{text:""}]})
                                    Transforms.insertNodes(editor,{type: 'paragraph', children: [{text:""}]})    
                                }else{
                                    alert("Неправильная ссылка")
                                }
                            }
                        }
                    }>
                    <Icon icon={'youtube'}/>
                </Button>
            </div>
        )
    }

    const VideoButton = () => {
        return(
            <div>
                <input type="file"
                    id="videoInput" name="videoInput"
                    accept="video/mp4,video/x-m4v,video/*"
                    onInput = {(e)=>{
                        if (e.target.files) {
                            uploadFile(e.target.files[0],(fileName) => {
                                Transforms.insertNodes(editor,{type: 'video', url: 'https://tkpoluslab.ru:8081/uploads/'+fileName, children: [{text:""}]})
                            })
                        }
                    }}
                    onClick={(e)=>{
                        e.value = ''
                        e.target.value = ''
                    }}
                    style={{display:'none'}}
                    ref = {videoRef}
                />
                <Button active={false} onMouseDown={
                    (e)=>{
                            videoRef.current.click()
                            //Transforms.insertNodes(editor,{type: 'image', url: url, children: [{text:""}]})
                        }
                    }>
                    <Icon icon={'video'}/>
                </Button>
            </div>
            
        )
    }

    const FileButton = () => {
        return(
            <div>
                <input type="file"
                    id="randomInput" name="randomInput"
                    onInput = {(e)=>{
                        if (e.target.files) {
                            uploadFile(e.target.files[0],(fileName) => {
                                Transforms.insertNodes(editor,{type: 'file', url: 'https://tkpoluslab.ru:8081/uploads/'+fileName, displayType:0, children: [{text:""}]})
                            })
                        }
                    }}
                    onClick={(e)=>{
                        e.value = ''
                        e.target.value = ''
                    }}
                    style={{display:'none'}}
                    ref = {randomRef}
                />
                <Button active={false} onMouseDown={
                    (e)=>{
                            randomRef.current.click()
                            //Transforms.insertNodes(editor,{type: 'image', url: url, children: [{text:""}]})
                        }
                    }>
                    <Icon icon={'file'}/>
                </Button>
            </div>
            
        )
    }

    const ConclusionButton = () => {
        return(
            <Button active={false} onMouseDown={
                (e)=>{
                        Transforms.insertNodes(editor,{type: 'conclusion', displayType: 0, children: [{text:""}]})
                    }
                }>
                <Icon icon={'conclusion'}/>
            </Button>
        )
    }

    const changeMarkData = (event,format)=>{
        event.preventDefault();
        const value =event.target.value
        addMarkData(editor,{format,value})
    }

    return(
        <div className={styles.toolbar}>
            {
                toolbarGroups.map((group,index) => 
                    <span key={index} className={styles.toolbarGrp}>
                        {
                            group.map((element) => 
                                {
                                    switch (element.type) {
                                        case 'block' :
                                            return <BlockButton key={element.id} {...element}/>
                                        case 'mark':
                                            return <MarkButton key={element.id} {...element}/>
                                        case 'dropdown':
                                            return <Dropdown key={element.id} {...element} />
                                        case 'image':
                                            return <Image key={element.id} {...element}/>
                                        case 'youtube':
                                            return <YoutubeButton key={element.id} {...element}/>
                                        case 'video':
                                            return <VideoButton key={element.id} {...element}/>
                                        case 'separator':
                                            return <Separator key={element.id} {...element}/>
                                        case 'gallery':
                                            return <ImageGallery key={element.id} {...element}/>
                                        case 'file':
                                            return <FileButton key={element.id} {...element}/>
                                        case 'conclusion':
                                            return <ConclusionButton key={element.id} {...element}/>
                                        default:
                                            return null
                                    }
                                }
                            )
                        }
                    </span>    
                )
            }
        </div>
    )
}

export {Toolbar, uploadFile};