import Header from "../Header/Header";
import about from '../../Images/Regular_size/aboutsplash.png';
import aboutCompressed from '../../Images/Compressed_Image/aboutsplash.png';
import fsi from '../../Images/Regular_size/logo_new.svg';
import { LazyLoadImage } from "react-lazy-load-image-component";
import styles from './CompanyActivity.module.css'
import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "effector-react";
import { addressStore } from "../Store/ConfigStore";
import inter from "../../Images/1chanel.mp4"
import Russia1 from "../../Images/Russia1.mp4"



function CompanyActivity() {
    const address = useStore(addressStore);
    let navigate = useNavigate();
    const aboutRef = useRef(null);
    const prosRef = useRef(null);
    const partnersRef = useRef(null);
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {

        const handleResize = (event) => {
            setWidth(event.target.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <div className={styles.holder}>
            <div className={styles.wrapper}>
                <LazyLoadImage
                    src={about}
                    placeholderSrc={aboutCompressed}
                    effect="blur"
                    className={styles.splash}
                />
                <div className={styles.topTabs}>
                    <div className={styles.topRightTab}>
                        <div className={styles.topRightTabHeader} >
                            О нашей компании
                        </div>
                        <div className={styles.topRightTabText}>
                            Компания «Научный центр «Полюс» - системный интегратор и разработчик ИТ-решений в сфере образования.<br /><br />
                            Компания основана в 2002 году. Изначально основным направлением деятельности была системная интеграция — проектирование, комплектация и развертывание ИТ-инфраструктуры образовательных учреждений.<br /><br />
                            Тем не менее, имея опыт работы в разработке и производстве и осознавая необходимость собственных продуктов и решений для образования, уже в 2015 году НЦ «Полюс» успешно участвует в конкурсе МОСТ Фонда содействия инновациям и становится его победителем. По итогам выполнения гранта было разработан первый продукт компании: система «Мобитест». Дистрибуцией «Мобитест» занимался партнер «Полюс», компания «Физикон».<br /><br />
                            В 2018 году, обозначив для себя новый перспективный сегмент — робототехнику и ИИ, НЦ «Полюс» подает заявку на участие в конкурсе Развитие НТИ Фонда содействия инновациям. Заявка на выполнение НИОКР по теме “Разработка программного комплекса поддержки нейросетевых алгоритмов интеллектуальной робототехнической системы обучения и развития ребенка” была поддержана Фондом и успешно выполнена компанией. По итогам НИОКР НЦ «Полюс» расширил свой продуктовый ряд, пополнив его, в частности ПО Виртуальный робот VIR (Virtual robot), ПО анализа каллиграфического написания алфавита «ACAP» (Alphabet caligraph analysis program) и образовательное ПО для социального робота Aldebaran Robotics NAO. Тогда же был сформирован научно-технический и учебно-методический задел для развития направления «Робототехника и ИИ».<br /><br />
                            В 2022 году компания формирует новую заявку в ФСИ «Облачная платформа поддержки смешанного режима преподавания и развивающего обучения в дошкольных учреждениях и начальной школе с применением подходов искусственного интеллекта». По итогам конкурса заявка НЦ «Полюс» получила грант на прохождение акселерационной программы и успешно завершила ее прохождение в 2023 году. Результатом акселерации стал новый продукт «Платформа речевого развития РобоЛого», включающий в себя как облачный сервис развивающего обучения с упором на логопедию, так и социальный робот MEOW как средство доставки контента и работы с детьми.<br /><br />
                            В 2022-23 годах компания «Полюс» успешно реализовала собственный проект по проектированию, разработке и производству цифровых лабораторий для школ, учреждений дополнительного образования, СПО и вузов «LFS PolusLab». Продукт включает в себя цифровые мультидатчики с периферией, ПО и учебно-методическое обеспечение по предметем «Физика», «Химия, «Биология», «Экология»,"Физиология".<br /><br />
                            программное обеспечение разработанное НЦ "Полюс" это "Полюс-Лаб Тест" и "Полюс-Лаб  Калибровка"<br /><br />
                            <a href="https://fasie.ru/" className={styles.imageWrapper}>
                                <img src={fsi} />
                            </a>
                        </div>
                        <div className={styles.headerVistavkaText}>
                            Выставка Россия-2023
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <video style={{ width: "80%" }} controls="controls" >
                                <source src={inter} type='video/webm; codecs="vp8, vorbis"' />
                                Тег video не поддерживается вашим браузером.
                                <a href={inter}>Скачайте видео</a>.
                            </video>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                            <video style={{ width: "80%" }} controls="controls" >
                                <source src={Russia1} type='video/webm; codecs="vp8, vorbis"' />
                                Тег video не поддерживается вашим браузером.
                                <a href={Russia1}>Скачайте видео</a>.
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CompanyActivity;