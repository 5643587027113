import './App.css';
import PageWrapper from './Components/PageWrapper/PageWrapper';
import {
  BrowserRouter as Router,
  Route, Routes, Navigate,
} from "react-router-dom";
import axios from "axios";
import NotFound from './Components/NotFound/NotFound';
import  { Redirect } from 'react-router-dom'


axios.interceptors.request.use(
  config => {
    config.headers['Authorization'] = `Bearer ${sessionStorage.getItem('token')}`;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

function App() {
  return (
    <div>
      <Router>
        <div>
          <Routes>
            {/* <Route exact path='/' element={<Navigate to="/main" />}></Route> Это пример редиректа */}
            <Route exact path='/' element={<PageWrapper />}></Route>
            <Route exact path='/support' element={<PageWrapper />}></Route>
            <Route exact path='/projects' element={<PageWrapper />}></Route>
            <Route exact path='/software' element={<PageWrapper />}></Route>
            <Route exact path='/project_view' element={<Navigate to="/" />}></Route>
            <Route exact path='/project_view/:id' element={<PageWrapper />}></Route>
            <Route exact path='/software_view/:id' element={<PageWrapper />}></Route>
            <Route exact path='/software_view' element={<Navigate to="/" />}></Route>
            <Route exact path='/contact' element={<PageWrapper />}></Route>
            <Route exact path='/moderation' element={<PageWrapper />}></Route>
            <Route exact path='/software_builder/:id' element={<PageWrapper />}></Route>
            <Route exact path='/software_builder' element={<Navigate to="/" />}></Route>
            <Route exact path='/project_builder/:id' element={<PageWrapper />}></Route>
            <Route exact path='/project_builder' element={<Navigate to="/" />}></Route>
            <Route exact path='/news_builder/:id' element={<PageWrapper />}></Route>
            <Route exact path='/news_builder' element={<Navigate to="/" />}></Route>
            <Route exact path='*' element={<NotFound/>}></Route>
            <Route exact path='/news' element={<PageWrapper />}></Route>
            <Route exact path='/news_view' element={<Navigate to="/" />}></Route>
            <Route exact path='/news_view/:id' element={<PageWrapper />}></Route>
            <Route exact path='/history_of_success' element={<PageWrapper />}></Route>
          </Routes>
        </div>
      </Router>
    </div>
  );
}


export default App;
