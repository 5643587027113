import { useState, useEffect } from 'react';
import styles from './FooterFooter.module.css'

function FooterFooter() {
    const [width, setWidth] = useState(window.innerWidth); // тут хранится ширина экрана
    useEffect(() => { // хук отслеживает изменение ширины экрана
        const handleResize = (event) => {
            setWidth(event.target.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            {width > 768 &&
                <div style={{ width: 1110, display: 'flex', marginTop: 30, marginLeft: 40, marginRight: 40, marginBottom: 13 }}>
                    <div style={{ marginLeft: 40 }}>
                        <div className={styles.FooterHeaderText} style={{ width: width > 1920 ? 255 : (19 / 312) * width + (1795 / 13), fontSize: width > 1920 ? 14 : (1 / 780) * width + (150 / 13) }}>
                            <span class="notranslate" translate="no">О компании</span>
                        </div><br />
                        <div className={styles.FooterText} style={{ width: width > 1920 ? 255 : (19 / 312) * width + (1795 / 13), fontSize: width > 1920 ? 14 : (1 / 390) * width + (118 / 13) }}>
                            <span class="notranslate" translate="no">Компания НЦ Полюс разрабатывает образовательные IT технологии и комплексные технические решения для повышения качества образования.</span>
                        </div><br /><br /><br /><br /><br /><br /><br />

                        <div className={styles.FooterText} style={{ fontSize: width > 1920 ? 14 : (1 / 390) * width + (118 / 13) }}>
                            © {new Date().getFullYear()} Copyright. All Rights Reserved.
                        </div>
                    </div>
                    <div style={{ flex: 1 }} />
                    <div>
                        <div className={styles.FooterHeaderText} style={{ fontSize: width > 1920 ? 14 : (1 / 780) * width + (150 / 13) }}>
                            Режим работы офиса
                        </div><br />
                        <div className={styles.FooterText} style={{ fontSize: width > 1920 ? 14 : (1 / 390) * width + (118 / 13) }}>
                            Пн: с 9-00 до 18-00
                        </div>
                        <div className={styles.FooterText} style={{ fontSize: width > 1920 ? 14 : (1 / 390) * width + (118 / 13), marginTop: 10 }}>
                            Вт: с 9-00 до 18-00
                        </div>
                        <div className={styles.FooterText} style={{ fontSize: width > 1920 ? 14 : (1 / 390) * width + (118 / 13), marginTop: 10 }}>
                            Ср: с 9-00 до 18-00
                        </div>
                        <div className={styles.FooterText} style={{ fontSize: width > 1920 ? 14 : (1 / 390) * width + (118 / 13), marginTop: 10 }}>
                            Чт: с 9-00 до 18-00
                        </div>
                        <div className={styles.FooterText} style={{ fontSize: width > 1920 ? 14 : (1 / 390) * width + (118 / 13), marginTop: 10 }}>
                            Пт: с 9-00 до 18-00
                        </div>
                        <div className={styles.FooterText} style={{ fontSize: width > 1920 ? 14 : (1 / 390) * width + (118 / 13), marginTop: 10 }}>
                            Суббота и Воскресенье - выходной
                        </div><br />
                    </div>
                    <div style={{ flex: 1 }} />

                    <div style={{ marginRight: 40 }}>
                        <div className={styles.FooterHeaderText} style={{ fontSize: width > 1920 ? 14 : (1 / 780) * width + (150 / 13) }}>
                            Адреса
                        </div><br />
                        <div className={styles.FooterText} style={{ width: width > 1920 ? 255 : (19 / 312) * width + (1795 / 13), fontSize: width > 1920 ? 14 : (1 / 390) * width + (118 / 13) }}>
                            <span class="notranslate" translate="no">Юридический адрес: 634029, г. Томск,ул. Белинского, 18<br /><br />
                                Фактический адрес: 634029, г. Томск, ул. Белинского, 18</span>
                        </div><br />
                    </div>
                </div>
            }
            {width <= 768 &&
                <div style={{ width: 1110, display: 'flex', marginTop: 30, marginLeft: 40, marginRight: 40, marginBottom: 13 }}>
                    <div style={{ marginRight: 20 }}>
                        <div className={styles.FooterHeaderText} style={{ width: width > 1920 ? 255 : (19 / 312) * width + (1795 / 13), fontSize: width > 1920 ? 14 : (1 / 780) * width + (150 / 13) }}>
                            <span class="notranslate" translate="no">О компании</span>
                        </div><br />
                        <div className={styles.FooterText} style={{ width: width > 1920 ? 255 : (19 / 312) * width + (1795 / 13), fontSize: width > 1920 ? 14 : (1 / 390) * width + (118 / 13) }}>
                            <span class="notranslate" translate="no">Компания НЦ Полюс разрабатывает образовательные IT технологии и комплексные технические решения для повышения качества образования.</span>
                        </div><br />
                        <div className={styles.FooterHeaderText} style={{ width: width > 1920 ? 255 : (19 / 312) * width + (1795 / 13), fontSize: width > 1920 ? 14 : (1 / 780) * width + (150 / 13) }}>
                            <span class="notranslate" translate="no">Режим работы офиса</span>
                        </div><br />
                        <div className={styles.FooterText} style={{ width: width > 1920 ? 255 : (19 / 312) * width + (1795 / 13), fontSize: width > 1920 ? 14 : (1 / 390) * width + (118 / 13) }}>
                            <span class="notranslate" translate="no">Пн.-Пт.: с 9-00 до 18-00<br />
                                Сб. и Вс. - выходной</span>
                        </div><br />
                        <div className={styles.FooterText} style={{ fontSize: width > 1920 ? 14 : (1 / 390) * width + (118 / 13) }}>
                            © {new Date().getFullYear()} Copyright. All Rights Reserved.
                        </div>
                    </div>
                    <div style={{ flex: 1 }} />
                    <div>
                        <div className={styles.FooterHeaderText} style={{ fontSize: width > 1920 ? 14 : (1 / 780) * width + (150 / 13) }}>
                            <span class="notranslate" translate="no">Адреса</span>
                        </div><br />
                        <div className={styles.FooterText} style={{ width: width > 1920 ? 255 : (19 / 312) * width + (1795 / 13), fontSize: width > 1920 ? 14 : (1 / 390) * width + (118 / 13) }}>
                            <span class="notranslate" translate="no">Юридический адрес: 634029, г. Томск,ул. Белинского, 18<br /><br />
                                Фактический адрес: 634029, г. Томск, ул. Белинского,<br /><br />
                                Фактический адрес в Москве: 109029, г. Москва, ул. Скотопрогонная, 27/26с1</span>
                        </div><br />
                    </div>
                </div>
            }

        </div>
    );
}

export default FooterFooter;
