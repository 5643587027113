const toolbarGroups = [
    [
        {
            id:1,
            format:'fontSize',
            type:'dropdown',
            options:[{text:'Маленький',value:'small'},{text:'Обычный',value:'normal'},{text:'Средний',value:'medium'},{text:'Большой',value:'huge'}]
        }
    ],
    [
        {
            id:2,
            format:'bold',
            type:'mark',
        },
        {
            id:3,
            format:'italic',
            type:'mark',
        },
        {
            id:4,
            format:'underline',
            type:'mark',
        },
        {
            id:5,
            format:'strikethrough',
            type:'mark',
        },
    ],
    [
        {
            id:6,
            format:'image',
            type:'image'
        },
        {
            id:7,
            format:'video',
            type:'video'
        },
        {
            id:8,
            format:'youtube',
            type:'youtube'
        },
        {
            id:9,
            format:'gallery',
            type:'gallery'
        },
        {
            id:10,
            format:'file',
            type:'file'
        },
    ],
    [
        {
            id:11,
            format:'superscript',
            type:'mark',
        },
        {
            id:12,
            format:'subscript',
            type:'mark',
        },
    ],
    [
        {
            id:13,
            format:'headingOne',
            type:'block',
        },
        {
            id:14,
            format:'headingTwo',
            type:'block',
        },
        {
            id:15,
            format:'headingThree',
            type:'block',
        },
        {
            id:16,
            format:'blockquote',
            type:'block',
        },
    ],
    [
        {
            id:17,
            format:'orderedList',
            type:'block'
        },
        {
            id:18,
            format:'unorderedList',
            type:'block'
        }
    ],
    [
        {
            id:19,
            format:'alignLeft',
            type:'block'
        },
        {
            id:20,
            format:'alignCenter',
            type:'block'
        },
        {
            id:21,
            format:'alignRight',
            type:'block'
        },
        {
            id:22,
            format:'alignJustify',
            type:'block'
        },
    ],
    [
        {
            id:23,
            format:'separator',
            type:'separator'
        },
        {
            id:24,
            format:'conclusion',
            type:'conclusion'
        },
    ],
]

export default toolbarGroups