import styles from './FooterHeader.module.css'
import { useState, useEffect } from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import logoPolus from '../../../Images/Regular_size/logoPolus.svg';
import convert from '../../../Images/Regular_size/convert.svg';
import exclamation from '../../../Images/Regular_size/exclamation.svg';

function FooterHeader() {
    let navigate = useNavigate();
    const [width, setWidth] = useState(window.innerWidth); // тут хранится ширина экрана
    useEffect(() => { // хук отслеживает изменение ширины экрана
        const handleResize = (event) => {
            setWidth(event.target.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
        <div >
            
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ width: "100vw" }}>
                    <div style={{ border: " 1px solid #AFAFAF", height: 0 }} />
                </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                <div style={{ width: 1110 }}>
                    <div >
                        <div style={{ display: 'flex' }}>
                            <div style={{ display: 'flex', flex: 1 }}>
                                <div onClick={() => navigate("/")} style={{ cursor: 'pointer', marginLeft: 20 }}>
                                    <LazyLoadImage
                                        onClick={() => window.scrollTo(0, 0)}
                                        src={logoPolus}
                                        height={width > 1920 ? 40 : (19 / 1560) * width + (229 / 13)}
                                        width={width > 1920 ? 91 : (41 / 1560) * width + (527 / 13)}
                                        effect="blur"
                                    />
                                </div>
                                {(width > 570) &&
                                    <>
                                        <div style={(width <= 768 && width >= 570) ? { display: 'flex', alignItems: 'center', flex: 1, justifyContent: 'flex-end', marginRight: 50 } : { display: 'flex', alignItems: 'center', flex: 1, justifyContent: 'center' }}>
                                            <NavLink to="/" className={styles.headerElement}>
                                                <div onClick={() => window.scrollTo(0, 0)}>Главная</div>
                                            </NavLink>
                                            <NavLink to="/software" className={styles.headerElement}>
                                                <div onClick={() => window.scrollTo(0, 0)}>Программное обеспечение</div>
                                            </NavLink>
                                            <NavLink to="/projects" className={styles.headerElement}>
                                                <div onClick={() => window.scrollTo(0, 0)}>Проекты</div>
                                            </NavLink>
                                            <NavLink to="/contact" className={styles.headerElement}>
                                                <div onClick={() => window.scrollTo(0, 0)}>Контакты</div>
                                            </NavLink>
                                        </div>
                                    </>
                                }

                                {(width < 570 && width > 430) &&
                                    <div style={{ display: 'flex', flex: 3, justifyContent: 'center' }}>
                                        <LazyLoadImage
                                            src={convert}
                                            effect="blur"
                                        />
                                        <div style={{ marginLeft: 14 }}>
                                            <a className={styles.link} href="mailto:support@tkpolus.ru">support@tkpolus.ru</a>
                                        </div>
                                    </div>
                                }
                            </div>


                            {(width > 768 || width < 570) &&
                                <div className={styles.phoneText} style={{ display: 'flex', alignItems: 'center', fontSize: width > 1920 ? 20 : (1 / 260) * width + (164 / 13) }}>
                                    +7 (3822) 528-999
                                </div>
                            }
                        </div>
                        <div>
                            {(width <= 768 && width >= 570) &&
                                <div style={{ display: 'flex', marginTop: 10 }}>
                                    <div style={{ display: 'flex', marginLeft: 20 }}>
                                        <LazyLoadImage
                                            src={convert}
                                            effect="blur"
                                        />
                                        <div style={{ marginLeft: 14 }}>
                                            <a className={styles.link} href="mailto:support@tkpolus.ru">support@tkpolus.ru</a>
                                        </div>
                                    </div>
                                    <div style={{ flex: 1 }} />
                                    <div className={styles.phoneText} style={{ display: 'flex', alignItems: 'center', fontSize: width > 1920 ? 20 : (1 / 260) * width + (164 / 13), marginRight: 50 }}>
                                        +7 (3822) 528-999
                                    </div>

                                </div>
                            }
                        </div>
                    </div>
                    <div style={{marginTop:15}}>
                        <div style={{ border: " 1px solid #AFAFAF", height: 0 }} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FooterHeader;
