import styles from './TrueNews.module.css';
import stylesDisplayBlock from './NewsDisplayBlock/NewsDisplayBlock.module.css';
import NewsDisplayBlock from "./NewsDisplayBlock/NewsDisplayBlock";
import stockNews from "../../Images/Regular_size/stocknews.png"
import DoubleSidedGradient from "../CommonComponents/DoubleSidedGradient/DoubleSidedGradient"
import { useRef, useState, useEffect } from "react";
import { useStore } from "effector-react";
import axios from "axios";
import { addressStore } from "../Store/ConfigStore";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useLocation, useNavigate } from "react-router-dom";

function TrueNews() {
    const [products, setProducts] = useState([])
    const address = useStore(addressStore);
    const [isLoad, setIsLoad] = useState(true)
    const [pageID, setPageID] = useState(2)
    const [isShowAlert, setIsShowAlert] = useState(false);
    const [id, setId] = useState(0);
    const [memI2, setMemI2] = useState(0);
    const [modalImage, setModalImage] = useState("");
    const [modalName, setModalName] = useState("");
    const [modalDescription, setModalDescription] = useState("");
    const [isShowModalAddProduct, setIsShowModalAddProduct] = useState(false);
    let navigate = useNavigate();
    useEffect(() => {
        axios.get(address + 'api/v1/articles/getDirectory?id=3&page=1&pageSize=10')
            .then(res => {
                setProducts(res.data["Articles"])
                if (res.data["Articles"].length < 10) {
                    setIsLoad(false)
                }
            })
    }, []);

    const loadNews = () => {
        axios.get(address + 'api/v1/articles/getDirectory?id=3&page=' + pageID + '&pageSize=10')
            .then(res => {
                setPageID(pageID + 1)
                setProducts([...products, ...res.data["Articles"]])
                if (res.data["Articles"].length < 10) {
                    setIsLoad(false)
                }
            })
    }

    const deleteProductHandler = (id) => {
        axios.post(address + `api/v1/articles/deleteArticle?id=` + id, {}, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
            }
        }).then(res => {
            // alert("Успешное удаление")
        }).catch((error) => {
            //alert(JSON.stringify(error))
            alert("Ошибка удаления товара")
        });
    }

    const changeHandler = (e) => {

        const file = e.target.files[0];
        //if (!file.type.match(imageMimeType)) {
        // alert("kek")
        axios.post(address + `api/v1/files/upload`, file, {
            headers: {
                'Content-Type': "image/png",
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
            }
        }).then(res => {
            setModalImage(res.data.FileName)
            // alert(JSON.stringify(address + "uploads/" +res.data.FileName))
        }).catch((error) => {
            // alert(JSON.stringify(error))
            alert("Надо авторизироваться")
        });
        return;
        // }
    }

    const createHandler = (e) => {
        if (modalImage !== '') {
            if (modalDescription !== '') {
                axios.post(address + `api/v1/articles/createArticle`, {
                    "Name": modalName,
                    "Image": modalImage,
                    "Description": modalDescription,
                    "Content": [],
                    "ParentId": 3
                }, {
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                    }
                }).then(res => {
                    navigate('/news_builder/' + res.data?.Id)
                }).catch((error) => {
                    alert(JSON.stringify(error))
                });
            } else {
                alert("Не введено описание")
            }
        } else {
            alert("Не загружено изображение")
        }
    }

    return (
        <div className={styles.justifier}>
            {isShowModalAddProduct &&
                <div className={styles.shadow} onClick={() => {
                    setIsShowModalAddProduct(false);
                    setModalImage("");
                    setModalName("");
                    setModalDescription("");
                }}>
                    <div style={{ color: 'white', display: 'flex', flexDirection: 'column' }} onClick={(e) => e.stopPropagation()}>
                        <div className={styles.wrapProd2} style={{ background: '#C5E2F1' }}>
                            <div style={{ margin: 12 }} >
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <div style={{ width: 0.04487179487179487 * 1920 + 123.84615384615384, height: 0.041025641025641026 * 1920 + 125.23076923076923 }}>
                                        <form style={{ cursor: "pointer", cursor: 'pointer' }}
                                            method="post" encType="multipart/form-data">
                                            <label className="input-file" style={{ cursor: 'pointer' }}>
                                                <input style={{ cursor: 'pointer', position: 'absolute', opacity: 0 }} type="file" name="file" id='image'
                                                    accept='.png, .jpg, .jpeg, .webp'
                                                    onChange={changeHandler}
                                                />
                                                <div >
                                                    <LazyLoadImage
                                                        src={address + "uploads/" + modalImage}
                                                        effect="blur"
                                                        width={0.04487179487179487 * 1920 + 123.84615384615384}
                                                        height={0.041025641025641026 * 1920 + 125.23076923076923}
                                                    />
                                                </div>
                                            </label>
                                        </form>
                                    </div>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <div className={styles.labDescription} style={{ flex: 1 }}>
                                        <input
                                            value={modalDescription}
                                            style={{ background: 'rgba(0,0,0,0.0)' }}
                                            className={styles.inputDescriptionStyle}
                                            placeholder="Введите описание"
                                            onChange={(e) => setModalDescription(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.createButtonWrapper} onClick={(e) => createHandler(e)}>
                            Создать
                        </div>
                    </div>
                </div>
            }
            {isShowAlert &&
                <div className={styles.shadow} onClick={() => { setIsShowAlert(false) }}>
                    <div className={styles.modalWrapper} style={{ position: 'relative' }}>
                        <div className={styles.modalText}>Вы действительно хотите удалить?</div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div className={styles.alertButtonWrapperDelete} onClick={() => {
                                deleteProductHandler(id)
                                setProducts(products.filter((y, j) => memI2 !== j))
                                setIsShowAlert(false)
                            }}>
                                Удалить
                            </div>
                            <div className={styles.alertButtonWrapperCancel}>
                                Отмена
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className={styles.newsBackground}>
                <DoubleSidedGradient />
                <div className={styles.newsTint} />
                {/*<svg width="1390" height="316" viewBox="0 0 1390 316" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.newsCross}>
                    <path d="M175.462 0.990365C175.645 -0.126268 177.251 -0.126284 177.435 0.990348L199.365 134.127C199.443 134.603 199.85 134.955 200.332 134.964L1390 158L200.332 181.036C199.85 181.045 199.443 181.397 199.365 181.873L177.435 315.01C177.251 316.126 175.645 316.126 175.462 315.01L153.515 181.773C153.445 181.344 153.104 181.009 152.673 180.946L1.79975 158.99C0.658434 158.823 0.658423 157.177 1.79974 157.01L152.673 135.054C153.104 134.991 153.445 134.656 153.515 134.227L175.462 0.990365Z" fill="#C5E2F1"/>
                </svg>*/}
                <svg width="1390" height="316" viewBox="0 0 1390 316" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.newsCross}>
                    <path d="M175.462 0.990365C175.645 -0.126267 177.251 -0.126284 177.435 0.990348L199.365 134.127C199.443 134.603 199.85 134.955 200.332 134.964L1390 158L200.332 181.036C199.85 181.045 199.443 181.397 199.365 181.873L177.435 315.01C177.251 316.126 175.645 316.126 175.462 315.01L153.515 181.773C153.445 181.344 153.104 181.009 152.673 180.946L1.79975 158.99C0.658434 158.823 0.658423 157.177 1.79974 157.01L152.673 135.054C153.104 134.991 153.445 134.656 153.515 134.227L175.462 0.990365Z" fill="#F6F6F6" />
                </svg>
                <div className={styles.topTextBox}>
                    <div className={styles.topLeftText}>Новостная лента</div>
                    <div className={styles.topRightText}>Здесь вы можете ознакомиться с самыми свежими новостями, связанными с деятельностью нашей компании.<br /> <br />
                        Узнать о новинках в сфере образования и новых разработках.</div>
                </div>
                <div className={styles.newsBox}>
                    {products.map((x, i) => {
                        return (
                            <NewsDisplayBlock
                                imageURL={address + "uploads/" + x.Image}
                                text={x.Description}
                                id={i}
                                realID={x.Id}
                                deleteHandler={() => {
                                    setIsShowAlert(true)
                                    setMemI2(i)
                                    setId(x.Id)
                                }}
                            />
                        )
                    })}
                    {sessionStorage.getItem('token') &&
                        <div className={stylesDisplayBlock.newsDisplayBlock + " " + styles.plusStyle} onClick={() => setIsShowModalAddProduct(true)}>
                            +
                        </div>
                    }
                    {(isLoad) &&
                        <div className={styles.loadMore} onClick={() => loadNews()}>
                            Загрузить ещё
                        </div>
                    }
                    {(!isLoad) &&
                        <div className={styles.loadMore}/>
                    }
                </div>
            </div>
        </div>
    );
}

export default TrueNews;
