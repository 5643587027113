import { useState } from 'react';
import SlateEditor from '../CommonComponents/Editor/SlateEditor/Editor';
import SlateRenderer from '../CommonComponents/Editor/SlateRenderer/Renderer';
import styles from './NewsView.module.css';
import { useStore } from "effector-react";
import { addressStore } from "../Store/ConfigStore";
import { LazyLoadImage } from 'react-lazy-load-image-component';
function NewsView() {
    const [current, setCurrent] = useState(0)
    const address = useStore(addressStore);
    const images = ["dxMVV28R7pwk2IZYselNIkefuxk8Q2zkHADt8uvs5tFIGgAtLoAYbDaFDJWS8nV6.png","sQVIsJutjHmpAVw1C94FeDpDqBLVetMCIH8R39KZcyuUo1cyVz2rPCGghqoB1sch.png","hhZI8EIMWqoFoBLmPKAgNV4tcW8ClOEZlZVLWUlNtZ2XMbxfGfhC2U7zYzVc2ohc.png","lsYwltspw3ihbvYnAXDzNmr8sbt5E5hHYl5FioPYblQ8IzC5b1lFfrynF0kVHRO5.png","xnoiVPk3jkv9x4MFaco4O9Z5CK9nFSMSzsnNdbbpNC8MJHEiRw258X7IwB5aRfQy.png","lkFd0ChgWUEVECbTF08A4NlzJgS8DJNeQVE3a3nsbKygkoqzOUhjVsZFrE0hZyva.png","fCwX8r2AgYUsvAWJ43xLcmaQjimhwpnSO2Lqx2yWmF4j9GRRJIboP4XEfHaCKCfE.png","Neg5U28PENb06PQvjkZjdkfEwi2I5wUyc9B5Iiir7EyqBrnaRdBXknFkDzF1qQlU.png","pKY5XOR6MDYLa0rNgUXGaBHtCiz04eR9U1XbKAZwi8K7pqPAPTtKn7iHxbMWuWfK.png","QsnfLMmLsWUvJ5e4dbJkydQJfS3n12mdfgKSq2siP6qKFsy3i1xdde9GOjIf8KwV.png","YiceDhLSWWbulqS7mDrwr9Xua0FRFgvfnOBNlxFkovyN2ReQSGxQUKtNmVq6BM7o.png"]
    return (
        <div style={{height:'40vw'}}>
            <div onKeyDown={(e)=>{
                if (e.key==="ArrowLeft"){
                    let next = current - 1
                    if (next < 0){
                        next = images.length - 1
                    }
                    setCurrent(next)
                }else if (e.key === "ArrowRight"){
                    let next = current + 1
                    if (next >= images.length){
                        next = 0
                    }
                    setCurrent(next)
                }
            }} tabIndex="0"
            className={styles.carouselWrapper}
            >
                <div className={styles.bottomArrow}>
                    {
                        (images.length>3)&&
                        <svg width="78" height="44" viewBox="0 0 78 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="44" height="78" rx="22" transform="matrix(0 -1 -1 0 78 44)" fill="#D8D8D8" fill-opacity="0.5" />
                            <path d="M54.5 12.5L37.6477 36.5L23.5 12.5" stroke="#0092DD" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    }
                </div>
                {images.map((x,i)=>{
                    const shifted = (i - current + images.length) % images.length
                    return (
                        <div className={current===i?styles.carouselElementFocused:shifted<3?styles.carouselElement:styles.carouselElementHidden} style={{order:shifted}}>
                            <LazyLoadImage
                                src={address + "uploads/" + images[i]}
                                effect="blur"
                                width={"100%"}
                                height={"100%"}
                                style={{ objectFit: current===i?'cover':'contain' }}
                            />
                        </div>
                    )   
                })}
                
            </div>
        </div>
    );
}

export default NewsView;
